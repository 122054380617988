<template>
  <div>
    <!--列表-->
    <div class="project-table" style="margin-top: 20px">
      <div class="flex align-center justify-between paddingBTM20">
        <div class="fontSize20gay">截图录像</div>
      </div>
      <el-row :gutter="30" class="screenshot-list">
        <el-col :span="6" class="item" v-for="(item,index) in tableData" :key="index"
                @click="handleShowDialog(item)">
          <!-- 2 摄像头截图 3 摄像头录像-->
          <el-image v-if="item.type == 2" :src="'api'+item.filePath" fit="cover" class="screenshot-img"></el-image>
          <div v-if="item.type == 3" class="screenshot-video" style="height: 200px !important;">
            <el-image :src="'api'+item.filePath" fit="cover" class="poster"></el-image>
            <div class="play-icon">
              <el-icon :size="40">
                <VideoPlay/>
              </el-icon>
            </div>
          </div>
          <!--          <video controls v-if="item.type == 3" :src="'api'+item.filePath" fit="cover" class="screenshot-img"></video>-->
          <div class="name">{{ item.name }}</div>
        </el-col>
      </el-row>
      <div class="flex justify-center paddingTD20">
        <el-pagination
            :currentPage="pageInfo.page"
            :page-size="pageInfo.limit"
            :page-sizes="[12, 20, 50, 100]"
            :small="true"
            :disabled="false"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--弹框-->
    <el-dialog :title="curItem?.name || '截图录像'" width="50%"
               v-model="showDialogVisible"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               @closed="closeDialog"
               style="padding: 24px;text-align: left">
      <div v-if="curItem" class="text-center">
        <el-image v-if="curItem.type == 2" :src="'api'+curItem.filePath" fit="contain"
                  style="height: 450px;"></el-image>
        <div v-if="curItem.type == 3 && !is_playBack" class="screenshot-video" @click="handlePlayBack">
          <el-image v-if="curItem.type == 3" :src="'api'+curItem.filePath" fit="contain" class="poster"></el-image>
          <div class="play-icon">
            <el-icon :size="40">
              <VideoPlay/>
            </el-icon>
          </div>
        </div>
        <div v-if="curItem.type == 3 && is_playBack" class="screenshot-video"
             :id="playbackPlayer&&playbackPlayer.elementId"></div>
      </div>
      <div class="dialog-footer marginTop30px text-right">
        <button class="qxBtn" type="button" @click="handleDelete">删除</button>
        <button class="subBtn marginLeft15" type="button" @click="handleDownload">下载</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {screenshotVideoPage, deleteScreenshotVideo} from "@/api/monitor";
import {Mp4Player as PlaybackFlvPlayer} from "@/utils/monitor";
import {projectName} from "@/utils/config";

/**
 * 检测是否转码完成
 * @param url
 * @returns {Promise<unknown>}
 */
const isEffective = url => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    video.addEventListener('loadeddata', () => {
      resolve(true)
      document.body.removeChild(video)
    })

    video.addEventListener('error', () => {
      reject(false)
      document.body.removeChild(video)
    })

    video.src = url

    video.style.width = '0'
    video.style.height = '0'
    document.body.appendChild(video)
  })
}

export default {
  components: {},
  data() {
    return {
      tableData: [],
      showDialogVisible: false,
      pageInfo: {
        limit: 12,
        page: 1,
        total: 0,
      },
      curItem: null,
      is_playBack: false,
      playbackPlayer: null,
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      screenshotVideoPage(this.pageInfo).then(res => {
        this.pageInfo.total = res?.data?.total || 0;
        this.tableData = res?.data?.records || []
      })
    },
    handleShowDialog(item) {
      this.curItem = item
      // 转码检测
      const url = projectName + this.curItem.davPath
      if (String(this.curItem.type) === '3') {
        isEffective(url).then(() => {
          // 转码成功
          this.curItem.transcodingSituation = true
        }).catch(() => {
          // 转码失败
          this.curItem.transcodingSituation = false
        }).finally(() => {
          this.showDialogVisible = true
        })
        return
      }
      this.curItem.transcodingSituation = true
      this.showDialogVisible = true
    },
    // 视频回放
    handlePlayBack() {
      // 检测是否存在
      const url = projectName + this.curItem.davPath
      if (this.curItem.transcodingSituation) {
        this.is_playBack = true
        if (this.playbackPlayer && this.playbackPlayer.flvPlayer) {
          this.playbackPlayer.destruction()
          this.playbackPlayer = null
        }
        this.playbackPlayer = new PlaybackFlvPlayer(url, 'playbackCamera')
        this.$nextTick(() => this.playbackPlayer.play())
      } else {
        this.$message.warning('视频转码中, 请稍后再试!')
      }
    },
    handleDelete() {
      if (!this.curItem || !this.curItem.id) return;
      this.$root.ElMessageBox.confirm(
          '你确定要删除吗?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
          }
      ).then(() => {
        deleteScreenshotVideo(this.curItem.id).then(res => {
          if (res.success) {
            this.closeDialog()
            this.getList()
            this.$message.success('删除成功')
          } else {
            this.$message.error(res?.message || '删除失败')
          }
        })
      })
    },
    handleDownload() {
      if (!this.curItem) return
      // 是否转码成功
      if (!this.curItem.transcodingSituation){
        this.$message.warning('视频转码中, 请稍后再试!')
        return
      }
      let url = this.curItem.type == 3 ? this.curItem.davPath : this.curItem.filePath
      if (!url) return;
      const temps = url.split('/')
      const ext = temps[temps.length - 1].split('.').slice(-1)[0]
      const name = `${this.curItem.name}.${ext}`
      fetch('api' + url)
          .then(res => res.blob())
          .then(blob => {
            const a = document.createElement('a')
            const objUrl = window.URL.createObjectURL(blob)
            a.download = name
            a.href = objUrl
            a.click()
          })
    },
    closeDialog() {
      this.showDialogVisible = false
      this.is_playBack = false
      this.curItem = null
      if (this.playbackPlayer && this.playbackPlayer.flvPlayer) {
        this.playbackPlayer.destruction()
        this.playbackPlayer = null
      }
    },
    handleSizeChange(e) {//修改每页显示条数
      this.pageInfo.limit = e;
      this.pageInfo.page = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.page = e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>

.screenshot-list {
  .item {
    margin-bottom: 30px;

    .name {
      padding-top: 20px;
    }

  }
}

.screenshot-img {
  width: 100% !important;
  height: 200px !important;
}

.screenshot-video {
  position: relative;
  width: 100% !important;
  height: 450px !important;

  .poster {
    width: 100%;
    height: 100%;
  }

  .play-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>